import React from 'react';
import './App.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import image1 from '../../images/image_1.svg'
import logo from '../../images/logo.svg'

function App() {
  return (
    <div className="App">

      <Navbar bg="light" variant="light" fixed="top">
        <Container>
          <Navbar.Brand>
            <img src={logo} alt="ED2 Digital" height="30" />
          </Navbar.Brand>
          <Nav className="mr-auto justify-content-center">
            <Nav.Link href="#who">Who we are</Nav.Link>
            <Nav.Link href="#reach-out">Say hello</Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      <Container className="who">
        <Row>
          <Col md={4}>

            <div className="who-text">
              <h2 id="who">
                Who we are
              </h2>

              <p>
                Ed2.Digital is a design, technology and AI startup based in London, U.K.
              </p>

              <p>
                With over 40 years of combined experience in software development, AI, design and user experience, these two Eds met while working together in London. Now several years later, they have united to create digital products.
              </p>

              <p>
                We are currently working on our first project, hoping to use AI to connect people with similar interests. Launching in 2023.
              </p>
            </div>

          </Col>
          <Col md={8}>

            <img src={image1} alt="Studio showcase" />

          </Col>
        </Row>
      </Container>

      <Container className="reach-out">
        <Row>
          <Col xs={12} className="text-center">

            <h2 id="reach-out">
              Say hello
            </h2>

            <p>
              You can get in touch with us on contact (at/@) ed2.digital
            </p>

          </Col>
        </Row>
      </Container>

      <Navbar bg="light" variant="light">
        <Container>
          <Nav>
            <Nav.Item className="copyright">
              &copy; Ed2 Digital Litmited 2023
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
      
    </div>
  );
}

export default App;